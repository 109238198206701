<template>
<div>
  <div id="appForm" class="mx-auto col-11 mt-5">
    <div class="flex flexLeft mx-auto contentsWidth">
      <p class="h4 text-center fw-3">{{base.year}}年度 {{base.name}} - 第{{change.sort}}回計画変更申請</p>
    </div>
    <hr class="title contentsWidth">

    <div class="notice mx-auto contentsWidth my-4 py-3 px-4">
      <p class="mb-1">・変更したい項目の「変更する」ボタンを押し、変更内容を入力してください。</p>
      <p class="mb-1">・数字は半角で入力してください。</p>
      <p class="mb-1">・添付ファイルについては、登録後に閲覧できます。</p>
    </div>
    <div class="mb-3 mx-auto contentsWidth">

    <div>
      <v-change-plan-control
        @updateNewStatus="updateNewStatus"
        @updateReason="updateReason"
        :userName="base.ans_name"
        :code="base.code"
        :status="change.status"
        :newStatus="newStatus"
        :reason="reason"
        :mailInfo="mailInfo"
        :writable="writable"
        :comments="comments"/>
    </div>

    <div class="mt-5">
      <div
        v-for="(field, index) in fields" :key="index"
        class="col-12 px-0">
        <v-app-field-change
          :fieldId='field.field_id'
          :fieldLabel="field.label"
          :fieldType="field.type"
          :description="field.description"
          :notice="field.notice"
          :is_required="field.is_required"
          :type="field.type"
          :label="field.label"
          :is_variable="field.is_variable"
          :input_number="field.input_number"
          :min_input_number="field.min_input_number"
          :max_input_number="field.max_input_number"
          :view_template="field.view_template"
          :options.sync="field.options"
          :rules="field.rules"
          :messages="field.messages"
          :params="field.params"
          :writable.sync="writable"
          :isChange="isChangeData[field.field_id]"
          :canChangePlan="field.can_change_plan"
          :approvalRequired="field.approval_required"
          :downloadFormFile="downloadFormFile"
          :isChangeReason="field.is_change_reason"
          :isChangeHistory="change.sort > 1"
          :changeHisLogs="changeHisLogs[field.field_id]"
          :firstLog="firstLog[field.field_id]"
          :linkFieldList="field.link_field_id_list"
          :linkedList="linkedList"
          :change_messages="field.change_messages"
          :downloadApploadedFile="downloadApploadedFile"
          @switchChange="switchChange"
          @updateLinkedList="updateLinkedList"
        />
        <hr>
      </div>
    </div>
    <div>
      <v-change-plan-control
        @updateNewStatus="updateNewStatus"
        @updateReason="updateReason"
        :userName="base.ans_name"
        :code="base.code"
        :status="change.status"
        :newStatus="newStatus"
        :reason="reason"
        :mailInfo="mailInfo"
        :writable="writable"
        :isUnderShow="true"
        :comments="comments"/>
    </div>
    <div class="contentsWidth mt-4 mb-3 flex flexCenter flexColumn">
      <p class="mb-1">添付ファイルについては、登録後に閲覧できます。</p>
    </div>

    <div class="mt-4 mb-3 flex flexCenter contentsWidth">
      <b-button
        v-show="hasPrevApp"
        @click="prevApp"
        class="btn btn-primary btn-lg mr-4 bold">
        前の申請
      </b-button>
      <b-button
        class="btn btn-lg mr-4 bold"
        to="/cms/applications/change/list">
        一覧に戻る
      </b-button>
      <b-button
        v-if="writable"
        :disabled="!hasChange"
        @click="save"
        type="button"
        variant="primary"
        class="btn bold mr-4">
        登録</b-button>
      <b-button
        v-show="hasNextApp"
        @click="nextApp"
        class="btn btn-primary btn-lg mr-4 bold">
        次の申請
      </b-button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import api from '@/modules/api';
// eslint-disable-next-line
import download from '@/modules/download';
import ChangePlanControl from '@/components/cms/changePlan/Control.vue';
import FieldChange from '@/components/parts/application/FieldChange.vue';
import CONST_STATUS from '@/constants/appStatus';
import USECASE from '@/constants/usecase';

export default {
  name: 'gasChangeForm',
  components: {
    'v-app-field-change': FieldChange,
    'v-change-plan-control': ChangePlanControl,
  },
  data() {
    return {
      changePlanId: null,
      sort: null,
      // 申請データ
      base: {},
      change: {},
      // 入力欄データ
      fields: {},
      usecases: {},
      constUsecases: USECASE.USECASE,
      isReturn: true,
      isChangeData: {},
      mailInfo: {},
      newStatus: null,
      reason: '',
      comments: {},
      changeHisLogs: {},
      firstLog: {},
      linkedList: [],
    };
  },
  computed: {
    hasChange() {
      let result = false;
      Object.keys(this.isChangeData).forEach((fieldId) => {
        if (this.isChangeData[fieldId] === true) {
          result = true;
          // eslint-disable-next-line
          return;
        }
      });
      return result;
    },
    // 受領の場合は編集不可
    writable() {
      return this.change.status !== CONST_STATUS.APP_STATUS.RECEIPT;
    },
    filterdIdList() {
      return this.$store.state.cmsChangePlansSearch.filterdIdList;
    },
    filterdIdListIndex() {
      return this.filterdIdList.indexOf(Number(this.changePlanId));
    },
    hasNextApp() {
      if (this.filterdIdList.length === 0) {
        return false;
      }
      const newIndex = this.filterdIdListIndex + 1;
      return newIndex < this.filterdIdList.length;
    },
    hasPrevApp() {
      if (this.filterdIdList.length === 0) {
        return false;
      }
      const newIndex = this.filterdIdListIndex - 1;
      return newIndex >= 0;
    },
    isDeletedUser() {
      return Object.keys(this.mailInfo).length === 0;
    },
    upFileKeys() {
      return this.$store.state.gasApplicationInput.upFileKeys;
    },
    tempUpFileList() {
      return this.$store.state.gasApplicationInput.tempUpFileList;
    },
  },
  methods: {
    setInitLinked() {
      this.linkedList = [];
      this.fields.forEach((field) => {
        const linkFieldIdList = field.link_field_id_list;
        if (this.isChangeData[field.field_id] && linkFieldIdList) {
          const linkList = linkFieldIdList.split(',');
          linkList.forEach((linkFieldId) => {
            this.switchChange(linkFieldId);
            this.updateLinkedList(linkFieldId);
          });
        }
      });
    },
    async fetchInitData(changePlanId) {
      this.change = {};
      const param = { changePlanId };
      const response = await api.send('/api/cms/applications/change/edit', param)
        .catch(async () => {
          await this.alert('計画変更申請を取得できませんでした。', false);
          this.$store.dispatch('page/offLoading');
          this.$router.push('/cms/applications/change/list');
        });
      const { data } = response;
      this.base = data.base;
      this.fields = data.fields;
      this.usecases = data.usecases;
      this.reason = '';
      if (data.change) {
        this.change = data.change;
        this.changePlanId = data.change.id;
        this.newStatus = data.change.status;
        this.comments = data.change.comments;
      }
      if (data.changePlanHistory) {
        this.changeHisLogs = data.changePlanHistory;
      }
      if (data.firstLog) {
        this.firstLog = data.firstLog;
      }
      const dbDataOld = { fields: data.fields, answers: data.old_answers };
      const dbDataNew = { fields: data.fields, answers: data.answers, reasons: data.reasons };
      this.setIsChangeList(dbDataNew);
      this.$store.commit('gasApplicationOldInput/setDbDataNoPage', dbDataOld);
      this.$store.commit('gasApplicationInput/setDbDataNoPage', dbDataNew);
      // ユーザーが削除されていたらからサーバーからnullが来るので空オブジェクトに置き換え
      this.mailInfo = response.data.mailInfo || {};
    },
    async save() {
      let msg = '上記の内容で計画変更申請を登録します。';
      if (this.change.status !== Number(this.newStatus) && !this.mailInfo.has_invalid_email_addressl && !this.isDeletedUser) {
        msg += '\nステータスが変更されているため、計画変更申請者にメールの送信と通知の登録が行われます。';
      } else if (this.change.status !== Number(this.newStatus) && !this.isDeletedUser) {
        msg += '\nステータスが変更されてますが、計画変更申請者へのメール送信がブロックされているため、通知の登録のみが行われます。';
      }
      if (Number(this.newStatus) === CONST_STATUS.APP_STATUS.RECEIPT) {
        msg += '\n計画変更申請を受領した場合、変更内容が申請に反映されます。以後、この計画変更申請の登録はできません。';
      }
      msg += '\n登録してもよろしいですか？';
      if (!await this.confirm(msg)) {
        return;
      }
      this.pageErrs = [];
      this.$store.dispatch('page/onWaiting');
      const storeData = {
        sort: this.change.sort,
        appId: this.change.application_id,
        apptypeId: this.base.application_type_id,
        userId: this.change.user_id,
        inputs: JSON.stringify(this.$store.state.gasApplicationInput.inputs),
        isChangeData: JSON.stringify(this.isChangeData),
        newStatus: this.newStatus,
        reason: this.reason,
        changeReasonInputs: JSON.stringify(this.$store.state.gasApplicationInput.changeReasonInputs),
        linkedList: JSON.stringify(this.linkedList),
      };
      const { files } = this.$store.state.gasApplicationInput;
      Object.keys(files).forEach((key) => {
        storeData[key] = files[key];
      });
      const response = await api.sendForm('/api/cms/applications/change/save', storeData)
        .catch(async (err) => {
          if (err.response.status === 422) {
            await this.alert('入力内容に誤りがありました。修正してください。', false);
            this.$store.commit('gasApplicationInput/setErrMessages', err.response.data.formErrs);
            this.$store.commit('userInfo/setErrorMsg', err.response.data.userErrs);
            this.pageErrs = err.response.data.pageErrs;
          }
          return false;
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        return;
      }
      await this.alert('データを更新しました。');
      this.$store.dispatch('page/onLoading');
      this.$store.commit('gasApplicationForm/init');
      this.$store.commit('gasApplicationForm/setIsCms', true);
      const { changePlanId } = this.$route.params;
      this.changePlanId = changePlanId;
      await this.fetchInitData(changePlanId);
      this.setInitLinked();
      this.$store.dispatch('page/offLoading');
    },
    async downloadFormFile(fieldId, optionId, fileName, isOld) {
      const param = {
        user_id: this.change.user_id,
        application_id: this.base.id,
        sort: this.change.sort,
        field_id: fieldId,
        option_id: optionId,
        answer_no: this.$store.state.gasApplicationInput.answer_no, // ファイルは当面１つ固定
        is_old: isOld,
      };
      const url = '/api/cms/applications/change/download/uploadedFile';
      this.$store.dispatch('page/onWaiting');
      const response = await api.send(url, param, true)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      this.$store.commit('gasApplicationInput/setAnsNum', 1);
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      download.blob(response.data, fileName);
    },
    setIsChangeList(dbDataNew) {
      const isChangeData = {};
      const canChangePlanList = {};
      this.fields.forEach((field) => {
        isChangeData[field.field_id] = false;
        canChangePlanList[field.field_id] = field.can_change_plan;
      });
      // 回答がある場合は、その変更フラグをONにする
      if (dbDataNew.answers) {
        dbDataNew.answers.forEach((ansewer) => {
          if (canChangePlanList[ansewer.field_id]) {
            isChangeData[ansewer.field_id] = true;
          }
        });
      }
      this.isChangeData = isChangeData;
    },
    switchChange(fieldId) {
      this.$set(this.isChangeData, fieldId, !this.isChangeData[fieldId]);
      Object.keys(this.fields).forEach((key) => {
        const FIELD_PARAMS = this.fields[key].params;
        if (this.fields[key].field_id === fieldId) {
          if ((!FIELD_PARAMS[0] || FIELD_PARAMS[0].param !== 3) || !FIELD_PARAMS[1]) {
            return;
          }
          this.$set(this.isChangeData, FIELD_PARAMS[1].param, this.isChangeData[fieldId]);
          return;
        }
        if ((!FIELD_PARAMS[0] || FIELD_PARAMS[0].param !== 3) || !FIELD_PARAMS[1] || FIELD_PARAMS[1].param !== fieldId) {
          return;
        }
        this.$set(this.isChangeData, this.fields[key].field_id, this.isChangeData[fieldId]);
      });
    },
    updateLinkedList(fieldId) {
      const index = this.linkedList.indexOf(fieldId);
      if (index !== -1) {
        this.linkedList.splice(index, 1);
        return;
      }
      this.linkedList.push(fieldId);
    },
    updateNewStatus(value) {
      this.newStatus = value;
    },
    updateReason(value) {
      this.reason = value;
    },
    async nextApp() {
      const newIndex = this.filterdIdListIndex + 1;
      this.$router.push({ path: `/cms/applications/change/edit/${this.filterdIdList[newIndex]}` }).catch(() => {});
      this.$store.dispatch('page/onLoading');
      this.interimId = this.filterdIdList[newIndex];
      await this.fetchInitData(this.filterdIdList[newIndex]);
      this.$store.dispatch('page/offLoading');
    },
    async prevApp() {
      const newIndex = this.filterdIdListIndex - 1;
      this.$router.push({ path: `/cms/applications/change/edit/${this.filterdIdList[newIndex]}` }).catch(() => {});
      this.$store.dispatch('page/onLoading');
      this.interimId = this.filterdIdList[newIndex];
      await this.fetchInitData(this.filterdIdList[newIndex]);
      this.$store.dispatch('page/offLoading');
    },
    async downloadApploadedFile(uploadedFileKey, index, fileName) {
      const fileKey = this.upFileKeys[uploadedFileKey][index];
      const url = '/api/cms/confupfile/fileDownload';
      this.$store.dispatch('page/onWaiting');
      if (!this.tempUpFileList[uploadedFileKey] || !this.tempUpFileList[uploadedFileKey][fileKey]) {
        const itemNumber = `${uploadedFileKey}-${fileKey}`;
        const tempUpUrl = '/api/cms/confupfile';
        const tempParam = {
          itemNumber: JSON.stringify(itemNumber),
        };
        const { files } = this.$store.state.gasApplicationInput;
        tempParam.file = files[uploadedFileKey];
        const tempResponse = await api.sendForm(tempUpUrl, tempParam, true)
          .catch((err) => {
            console.error(err);
          });
        if (!tempResponse) {
          this.$store.dispatch('page/offWaiting');
          await this.alert('ファイルのダウンロードに失敗しました。再度アップロードしてお試しください。');
          return;
        }
        const saveTempResponse = {
          target: uploadedFileKey,
          subKey: fileKey,
          path: tempResponse.data.path,
        };
        this.$store.commit('gasApplicationInput/saveTempPath', saveTempResponse);
      }
      const path = this.tempUpFileList[uploadedFileKey][fileKey];
      const param = {
        fileName: path,
      };
      const response = await api.send(url, param, true)
        .catch((err) => {
          console.error(err);
        });
      this.$store.dispatch('page/offWaiting');
      if (!response) {
        await this.alert('ファイルのダウンロードに失敗しました。');
        return;
      }
      download.blob(response.data, fileName);
    },
  },
  // ロード画面
  async created() {
    this.$store.dispatch('page/onLoading');
    const { changePlanId } = this.$route.params;
    this.changePlanId = changePlanId;
    await this.fetchInitData(changePlanId);
    this.$store.dispatch('page/offLoading');
  },
};
</script>

<style scoped>
  .notice {
    background-color: #F3F3F3;
  }

  .bottonArea {
    width: 1100px;
  }

  .label-wrap {
    width: 270px;
    margin-right: 30px;
  }
  .input-wrap {
    width: 780px;
  }

  input.form-control {
    height: 50px;
  }

  .change-btn {
    margin-top: 10px;
    width: 100px;
    height: 40px !important;
    font-size: 14px !important;
    font-weight: bold;
    background-color: #fff !important;
    border-radius: 18px;
  }

  .on-change {
    border: 2px solid #293CB0 !important;
    color: #293CB0 !important;
  }

  .off-change {
    border: 2px solid #8E8E8E !important;
    color: #8E8E8E !important;
  }

  hr.change {
    border-top: 3px double;
  }

  div.short-middle-wrap {
    width: 335px;
  }

  div.short-middle-wrap-second {
    margin-left: 10px;
  }

  div.input-left-wrap {
    width: 680px;
  }
  div.input-right-wrap {
    margin-left: 10px;
    width: 70px;
  }

  div.close-wrap {
    top: 50px;
    position: relative;
  }

  .drop {
    height: 120px;
    width: 100%;
    background-color: #F3F3F3;
    border-color: #CCC;
    border-style: dashed;
    border-radius: 10px;
    border-width: 1px;
  }

  .dragText {
    color: #BBB;
  }

  .fileText {
    color: #0A8EA7;
  }

  .fileNotation {
    color: #BBB;
  }

  .is-dragging.drop {
    background-color: #E6E6E6;
    border-color: #6E6E6E;
  }

  .is-dragging {
    color: #6E6E6E;
  }

  .selectFile {
    cursor: pointer;
  }
  .selectFile:hover {
    text-decoration: underline;
  }

  .fileName {
    margin-top: 16px;
  }

  .control-wrap {
    background-color: #DDD;
  }

  .cotrol-line {
    height: 2px;
    background-color: #BBB;
  }

  .opacity03 {
    opacity: 0.3;
  }

</style>
